import React, {Fragment} from "react";
import {
    SimpleForm,
    DateInput,
    SaveButton,
    useNotify,
    ReferenceInput,
    Loading,
    AutocompleteInput,
    useGetList,
} from 'react-admin';
import { format } from 'date-fns';
import { fetchCsStatisticsPanel } from "../../../../api";
import Box from "@mui/material/Box";
import ModuleConfig from "../index";
import MonthlyCandidatesByClient from "../metrics/monthly_candidates_by_client";
import DailyCandidatesByClient from "../metrics/daily_candidates_by_client";
import MonthlyInterviewedUniqueByClient from "../metrics/monthly_interviewed_unique_by_client";
import LdeHistoryOfClient from "../metrics/lde_history_of_client";

const CustomerSuccessPanel = () => {
    const ModuleIcon = ModuleConfig.icon;
    const notify = useNotify();
    const { data: clients } = useGetList('clients', {
        filter: {showInStats: true},
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'name', order: 'ASC' },
    });

    const endpointOptions = [
        { id: 'monthly_candidates_by_client', name: 'Candidatos por mes' },
        { id: 'daily_candidates_by_client', name: 'Candidatos por día' },
        { id: 'monthly_interviewed_unique_by_client', name: 'Conversiones por mes' },
        { id: 'lde_history_of_client', name: 'Historial preguntas/respuestas LdE' },
    ];
    
    const firstDayOfMonth = new Date().setDate(1);
    const today = new Date();

    const isFullfilledForm = React.useRef(false);
    const [loading, setLoading] = React.useState(false);
    const [client, setClient] = React.useState([]);
    const [endpoint, setEndpoint] = React.useState(null);
    const [selectedModule, setSelectedModule] = React.useState(null);
    const [modules, setModules] = React.useState([]);
    const [clientByModules, setClientByModules] = React.useState([]);
    const [from, setFrom] = React.useState(format(firstDayOfMonth, 'yyyy-MM-dd'));
    const [to, setTo] = React.useState(format(today, 'yyyy-MM-dd'));
    const [statisticsResponse, setStatisticsResponse] = React.useState(null);

    const handleSetFrom = (event) => {
        const value = event.target.value;
        setFrom(value);
    }

    const handleSetTo = (event) => {
        const value = event.target.value;
        setTo(value);
    }

    const handleSetClient = (client) => {
        setClient(client);
    }

    const handleSetModule = (module) => {
        setSelectedModule(module);
        const selectedModuleClients = clientByModules[module] || [];

        if (selectedModuleClients.length > 0) {
            const ids = selectedModuleClients.map(item => item.id);

            setClient(ids);
        }
    }

    const handleSetEndpoint = (endpoint) => {
        if (endpoint === '') {
            endpoint = null;
        }
        
        setEndpoint(endpoint);
    }

    const handleSubmit = async () => {
        setLoading(true);

        fetchCsStatisticsPanel(from, to, client, endpoint)
            .then((res) => {
                setStatisticsResponse(res.json);
                notify('ra.notification.success_operation', { type: 'success' });
                setLoading(false);
            })
            .catch((error) => {
                notify('ra.notification.bad_operation', { type: 'error' });
                setLoading(false);
            });
    };

    React.useEffect(() => {
        if (from && to && client && ! isFullfilledForm.current) {
            isFullfilledForm.current = true;
        }
    }, [from, to, client]);


    React.useEffect(() => {
        let clientByModule = {};

        (clients || []).forEach(client => {
            if (Array.isArray(client?.client_has_modules)) {
                client.client_has_modules.forEach(clientHasModule => {
                    const moduleId = clientHasModule.module_type.id;

                    if (!clientByModule[moduleId]) {
                        clientByModule[moduleId] = [];
                    }

                    clientByModule[moduleId].push({ id: client.id, name: client.name });
                })
            }
        });

        setClientByModules(clientByModule);

        const modules = Object.keys(clientByModule).map(module => ({
            id: module,
            name: module
        }));
        setModules(modules);
    }, [clients]);

    return (
        <Fragment>
            {loading ? (<Loading />) : (
                <>
                    <SimpleForm toolbar={null} onSubmit={handleSubmit}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                            <SaveButton
                                sx={{
                                    margin: '8px 16px 26px 16px',
                                    width: 'auto',
                                    padding: '0px 16px 0px 16px',
                                    '& .MuiSvgIcon-root': {
                                        marginTop: '-2px',
                                    }
                                }}
                                icon={<ModuleIcon />}
                                label="Consultar"
                            />
                            <DateInput
                                source="from"
                                label="Fecha de inicio"
                                value={from}
                                defaultValue={from}
                                inputProps={{
                                    min: '2020-01-01',
                                    max: to ? to : firstDayOfMonth,
                                }}
                                onChange={handleSetFrom}
                            />
                            <DateInput
                                source="to"
                                label="Fecha de fin"
                                value={to}
                                defaultValue={to}
                                inputProps={{
                                    min: from ? from : '2020-01-01',
                                    max: today,
                                }}
                                onChange={handleSetTo}
                            />
                            <ReferenceInput
                                reference="clients"
                                source="clients"
                                filter={{showInStats: true}}
                                perPage={1000}
                                sort={{ field: 'name', order: 'ASC' }}
                                queryOptions={{ cacheTime: 300000, staleTime: 300000 }}
                            >
                                <AutocompleteInput
                                    defaultValue={client}
                                    optionText="name"
                                    filterToQuery={(searchText) => ({ name: searchText })}
                                    onChange={handleSetClient}
                                    multiple={true}
                                    style={{ maxWidth: '50%' }}
                                />
                            </ReferenceInput>

                            <AutocompleteInput
                                defaultValue={selectedModule}
                                source="modules"
                                style={{ maxWidth: '50%' }}
                                choices={modules || []}
                                optionText="name"
                                optionValue="id"
                                onChange={handleSetModule}
                            />

                            <AutocompleteInput
                                defaultValue={endpoint}
                                source="endpoints"
                                style={{ maxWidth: '50%' }}
                                choices={endpointOptions}
                                optionText="name"
                                optionValue="id"
                                onChange={handleSetEndpoint}
                            />
                        </div>
                    </SimpleForm>

                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '85vw' }}>
                        { statisticsResponse?.monthly_candidates_by_client &&
                            <Box mb={4} sx={{ overflowX: 'auto', width: '100%' }}>
                                <MonthlyCandidatesByClient data={statisticsResponse.monthly_candidates_by_client} />
                            </Box>
                        }

                        { statisticsResponse?.daily_candidates_by_client &&
                            <Box mb={4} sx={{ overflowX: 'auto', width: '100%' }}>
                                <DailyCandidatesByClient data={statisticsResponse.daily_candidates_by_client} />
                            </Box>
                        }

                        { statisticsResponse?.monthly_interviewed_unique_by_client &&
                            <Box mb={4} sx={{ overflowX: 'auto', width: '100%' }}>
                                <MonthlyInterviewedUniqueByClient data={statisticsResponse.monthly_interviewed_unique_by_client} />
                            </Box>
                        }

                        { statisticsResponse?.lde_history_of_client &&
                            <Box mb={4} sx={{ overflowX: 'auto', width: '100%' }}>
                                <LdeHistoryOfClient data={statisticsResponse.lde_history_of_client} />
                            </Box>
                        }
                    </Box>
                </>
            )}
        </Fragment>
    );
};

export default CustomerSuccessPanel;
