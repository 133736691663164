import {
    List,
    Datagrid,
    EmailField,
    TextField,
    ShowButton,
    BooleanField,
    SearchInput,
    useListController,
    Loading
} from 'react-admin';
import { MODULE_IDS } from "../../../../config/api";
import DeleteUsersField from "../../../molecules/delete-users-field";
import i18n from "../../../assets/i18n";
import React from "react";

const ClientsFilter = [
    <SearchInput placeholder="id" source="id" />,
    <SearchInput placeholder="name" source="name" />,
]

const ClientsList = () => {
    const controller = useListController();

    if (controller.isLoading) {
        return <Loading />;
    }

    return (
        <List filters={ClientsFilter} >
            <Datagrid  >
                <TextField source="id" />
                <TextField source="name" />
                <BooleanField source="is_velora" label="Cliente Velora" />
                <BooleanField source="is_free_trial" label={i18n.es.contract.is_free_trial} />
                <BooleanField source="is_velora_assesment" label="Velora Assesment" />
                <EmailField source="email" />
                <TextField source="max_users" />
                <TextField source="max_jobflows" label={i18n.es.contract.max_jobflows}/>
                <TextField source="max_persons" label={i18n.es.contract.max_persons}/>
                <DeleteUsersField moduleId="talent_acquisition" moduleName="Candidaturas" label="Borrar datos de módulo de Candidaturas" />
                <BooleanField source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.enabled'} />
                <DeleteUsersField moduleId="employee_line" moduleName="Línea de empleado" label="Borrar datos de módulo de Línea de empleado" />
                <BooleanField source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.enabled'} />
                <DeleteUsersField moduleId="on_board" moduleName="Onboarding" label="Borrar datos de módulo de Onboarding" />
                <BooleanField source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.enabled'} />
                <BooleanField source={'module_' + MODULE_IDS.ID_FEEDBACK + '.enabled'} />
                <BooleanField source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.enabled'} />
                <BooleanField source="show_in_stats" />
                <ShowButton />
            </Datagrid>
        </List>
    );
}

export default ClientsList;
